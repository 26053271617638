<template>
  <div>
    <div class="prod-list-wrap">
      <div class="title-wrap">
        <img src="./../../../assets/order/产品信息@2x.png">
        <span class="tip">产品信息</span>
<!--        <span class="orangeTip">（{{ cartInfoTip }}，特别提醒订单开立时，单张订单避免出现同一型号的不同质量等级）</span>-->
        <span class="orangeTip">(特别提醒订单开立时，单张订单避免出现同一型号的不同质量等级。"免运费"标识产品仅对共享仓商家在其所入仓覆盖范围内免费配送，同笔订单有一台“免运费”产品，则享受整单免运费。）</span>
      </div>
      <div class="product-list-wrap">
        <div class="product-info-wrap" v-for="(item, index) of productList" :key="index">
          <div>
            <div class="chekImgBox">
              <div
                class="prod-img-wrap"
              >
                <h-img2
                  class="prod-img"
                  :imgUrl="'/product/pic.nd?productId=' + item.productCode"
                  :errorImg="
                '/assets/new/images/product/' + item.materialGroupCode + '.jpg'
              "
                  :isUseBasePath="true"
                  alt=""
                />
              </div>
            </div>
            <div class="detail-wrap">
              <p class="name">
                <span class="freeShipping" v-if="item.productLabel && item.productLabel.indexOf('15691143850')!==-1">
                    免运费
                </span>
                <span class="bright" v-if="item.productLabel && item.productLabel.indexOf('15691143849')!==-1">
                    璀璨
                </span>
                {{ item.model }}
              </p>
              <div class="colorGroup">
                <p class="color">品牌：{{ item.brandName }}</p>
                <p
                  class="color two"
                  style="margin-left:16px;"
                  :title="item.colour"
                >
                  颜色：{{ item.colour }}
                </p>
              </div>
              <div class="tiji">
                <p class="brand">体积：{{ item.volume }}m³
                  <!--                  v-if="item.productLabel && item.productLabel.indexOf('15691143850')!==-1"-->
                  </p>
              </div>
            </div>
            <div class="price-wrap">
              <!-- 从这里开始 -->
              <div class="discutBox">
                <p
                  class="discuinfo"
                  v-show="item.invStatusName"
                >
                  质量等级：{{ item.invStatusName }}
                </p>
                <p
                  class="discuinfo"
                  v-show="item.invStatusTypeName"
                >
                  补差类型：{{ item.invStatusTypeName }}
                </p>
              </div>
              <div>
                <p
                  class="info ck"
                  :title="item.orgName"
                >
                  仓库：{{item.warehouseName }}
                </p>
              </div>
            </div>
            <div class="price-wrap price-wrapF">
              <div style="display: block">
                <p
                  class="info"
                  :title="item.warehouseName"
                >
                  组织：{{
                    item.orgName
                  }}
                </p>
              </div>
              <div>
                <p
                  class="info"
                  :title="item.bigQty"
                >
                  可用库存：{{ item.bigQty }}
                </p>
              </div>

            </div>
            <div class="detail-wrap" v-if="(trans=='04'||trans=='08')&&haveDeliveryFee">
              <p class="name">
                小计
              </p>
              <div class="colorGroup">
                <p class="color hk">货款：￥{{item.bprice && item.borderedQty ?( item.bprice * item.borderedQty).toFixed(2):0 }}</p>
              </div>
              <div class="tiji">
                <p class="brand hk">服务费：￥{{ item.serviceMoney||0 }}</p>
              </div>
            </div>
            <div class="detail-wrap" v-if="(trans!='04'&&trans!='08')||!haveDeliveryFee">
              <p class="name">
                小计：￥{{item.bprice && item.borderedQty ? (item.bprice * item.borderedQty).toFixed(2):0 }}
              </p>
<!--              <div class="colorGroup">-->
<!--                <p class="color hk">￥{{item.bprice && item.borderedQty ? (item.bprice * item.borderedQty).toFixed(2):0 }}</p>-->
<!--              </div>-->
<!--              <div class="tiji">-->
<!--                <p class="brand hk">体积：￥{{ (item.volume * item.borderedQty).toFixed(3) }}</p>-->
<!--              </div>-->
            </div>
            <div class="close">
              <a-popconfirm
                placement="topRight"
                title="确认删除该产品？"
                ok-text="确认"
                cancel-text="取消"
                @confirm="delProduct(index)"
              >
                <i class="iconfont icon-guanbi-gouwuche iconfontgwc"></i>
              </a-popconfirm>
            </div>
          </div>
          <div
            class="typeing-wrap down"
          >
            <p class="price" style="margin-left: 24px">
              <span class="new-price">
                      销售单价：<a-input-number style="width:120px;display:inline-block" v-model="item.bprice"
                                  @blur="changePrice($event,item)" /></span>
            </p>
            <div class="count-wrap">
              <p>销售数量：</p>
              <number-box
                class="number-box"
                :curVal.sync="item.borderedQty"
                :maxVal="item.maxQty"
                :minVal="0"
                @getNumber="dataChange(index)"
              />

            </div>
          </div>
        </div>
      </div>
      <div class="modelClick" @click="openAdd" v-show="productList.length <= 20"> <img src="./../../../assets/baseInfo/录入添加@2x.png">新增产品信息</div>
    </div>

  </div>
</template>

<script>
import { sysconfig } from "@/views/order/channelExamine/api";

export default {
  name: "ProductList",
  props: {
    productList: {
      type: Array,
      required: true
    },
    type: {
      type: String
    },
    modelOrderNum: {
      type: Number,
      default: function() {
        return 0;
      }
    },
    AllOrderNum: {
      type: Number,
      default: function() {
        return 0;
      }
    },
    show4Message: {
      type: String,
      default: function() {
        return "";
      }
    },
    show5Message: {
      type: String,
      default: function() {
        return "";
      }
    },
    isSpeclalShop: {
      type: String,
      default: function() {
        return "1";
      }
    },
    XSLX: {
      type: String,
      default: function() {
        return "retail";
      }
    },
    trans:{
      default: function() {
        return "";
      }
    },
    haveDeliveryFee:{
      default: function() {
        return "";
      }
    },
  },
  data() {
    return {
      cartInfoTip: "",
      outIndex:null,
    };
  },
  mounted() {
    this.getMessage();

  },
  computed: {
    companyList() {
      let obj = this.productList
      // 这里先循环一下整个数组取出他的每一个对象
      obj.forEach((item) => {
        // 然后再通过for in方法循环对象
        for (var key in item) {
          if (item[key]) {
            // 判断一下对象中的每个值是否都存在或者有值，如果有的话那就通过正则把来截取他小数点的后4位
            item[key] = String(item[key]).replace(/^(.*\..{4}).*$/, '$1')
          } else {
            // 否则就把当前的值改为'无'
            item[key] = '无'          }
        }
      })
      // 最后return
      return obj
    },
  },
  watch: {
    isSpeclalShop:{
      handler (val) {
        this.outIndex = null

      },
      deep: true,  // 可以深度检测到 obj 对象的属性值的变化
      immediate: true //刷新加载  立马触发一次handler
    },
    XSLX:{
      handler (val) {
        this.outIndex = null
      },
      deep: true,  // 可以深度检测到 obj 对象的属性值的变化
      immediate: true //刷新加载  立马触发一次handler
    },
    trans:{
      handler (val) {
      },
      deep: true,  // 可以深度检测到 obj 对象的属性值的变化
      immediate: true //刷新加载  立马触发一次handler
    },
    haveDeliveryFee:{
      handler (val) {
      },
      deep: true,  // 可以深度检测到 obj 对象的属性值的变化
      immediate: true //刷新加载  立马触发一次handler
    },
    // productList: {
    //   handler (val) { //这是vue的规定写法，当你watch的值发生变化的时候，就会触发这个handler，这是vue内部帮你做的事情
    //     if(this.XSLX == 'retail' && this.isSpeclalShop == 1) {
    //       if(val.length > 0 && this.outIndex!==null){
    //         this.reset()
    //         let currentModelCount = 0 // 当前产品型号数量
    //         let oldCount = 0 // 产品列表总数量
    //         this.productList.forEach((el, index) => {
    //           if(index == this.outIndex) {
    //             oldCount += Number(this.productList[this.outIndex].borderedQty)
    //           }else {
    //             oldCount += Number(el.borderedQty)
    //           }
    //           if(el.model == this.productList[this.outIndex].model) {
    //             if(index == this.outIndex) {
    //               currentModelCount += Number(this.productList[this.outIndex].borderedQty)
    //             }else {
    //               currentModelCount += Number(el.borderedQty)
    //             }
    //           }
    //         })
    //
    //         let sameNum = 0
    //         let allOther = 0
    //         this.productList.forEach((items,indexItem )=>{
    //           if( this.productList[this.outIndex].model == items.model && indexItem !== this.outIndex ) {
    //             sameNum += Number(items.borderedQty)
    //             this.$forceUpdate()
    //           }
    //           if(indexItem!== this.outIndex){
    //             allOther += Number(items.borderedQty)
    //             this.$forceUpdate()
    //           }
    //         })
    //         this.productList.forEach((items,index )=>{
    //           if( this.productList[this.outIndex].model == items.model ) {
    //             if(index == this.outIndex) {
    //               this.productList[this.outIndex].maxQty = this.modelOrderNum-sameNum
    //               this.$forceUpdate()
    //             } else {
    //               items.maxQty = 999
    //               this.$forceUpdate()
    //             }
    //             this.$forceUpdate()
    //           }
    //         })
    //
    //         // 如果当前商品型号数量超过singleLimit，则跳出并提示
    //         if(currentModelCount > this.modelOrderNum) {
    //           this.$message.warning(this.show4Message)
    //
    //           this.outIndex = null
    //           return false
    //         } else{
    //           this.reset()
    //         }
    //         // 如果总商品数量超过multipleLimit，则跳出并提示
    //         if(oldCount > this.AllOrderNum) {
    //           this.$message.warning(this.show5Message)
    //           this.productList.forEach((items,index )=>{
    //             if( this.productList[this.outIndex].model == items.model ) {
    //               // if(index == this.outIndex) {
    //               //   this.productList[this.outIndex].maxQty = this.modelOrderNum - oldCount
    //               //   console.log(this.productList[this.outIndex].maxQty);
    //               // } else {
    //               //   index.maxQty = 0
    //               // }
    //
    //
    //               this.$forceUpdate()
    //             }
    //           })
    //           this.outIndex = null
    //           return
    //         } else{
    //           this.reset()
    //         }
    //       }
    //     }
    //   },
    //   deep: true,  // 可以深度检测到 obj 对象的属性值的变化
    //   immediate: true //刷新加载  立马触发一次handler
    // }
  },
  methods: {

    // 获取零售单同一订单总台数
    getOrderAllNum(){
      sysconfig({key:'QD_OPEN_SMALL_LOANS'}).then(res => {
        this.AllOrderNum = res.data.data
      })
    },
    // 零售单单型号数量校验
    getOrderNum(){
      sysconfig({key:'ORDER_MODEL_NUMBER'}).then(res => {
        this.modelOrderNum = res.data.data
      })
    },
    classify(arr) {
      var map = {},dest = [];
      for(var i = 0; i < arr.length; i++){
        var ai = arr[i];
        if(!map[ai.model]){
          dest.push({
            model: ai.model,
            data: [ai]
          });
          map[ai.model] = ai;
        }else{
          for(var j = 0; j < dest.length; j++){
            var dj = dest[j];
            if(dj.model == ai.model){
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      return dest
    },
    changePrice(e, item) {
      console.log('修改价格时￥￥￥￥￥￥￥￥￥￥￥',item);
      if (item.type == 1 && item.bprice) {
        if (Number(item.bprice) < item.min || Number(item.bprice) > item.max) {
          if (item.WLtype=='样机') {
            this.$message.warning(`您录入的价格不符合产品公司规定，请按要求录入。`);
          }else{
            if(item.haveRetailL){
              this.$message.warning(`请在默认建议零售价的${item.bei}倍范围内录入价格`);
            }else{
              this.$message.warning(`您录入的价格不符合产品公司规定，请按要求录入。`);
            }

          }
          item.bprice = item.bprice1;
          return;
        }
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getMessage() {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id: "14187495683"
      });
      this.cartInfoTip = msg.msgDesc;
    },
    //编辑
    edit(item) {
      this.$emit("edit", item);
    },
    // dataChange(item,index,e) {
    //   this.$emit("dataChange", item,index,e);
    // },
    dataChange(outIndex){
      this.outIndex = outIndex
      this.$emit("dataChange",outIndex);
    },
    // 选中商品
    itemClick(item, e) {
      item.checked = e.target.checked;
      this.$forceUpdate();
    },
    //删除商品
    delProduct(index) {
      if(this.XSLX == 'retail') {
        this.$emit('delProduct',index)
      }else {
        this.productList.splice(index, 1);
      }
      // this.productList.splice(index, 1);
    },
    getTotalProd() {
      return this.productList.reduce((sum, item) => {
        if (item.checked) {
          return sum + (item.borderedQty || 0);
        } else {
          return sum;
        }
      }, 0);
    },
    getTotalVol() {
      return this.productList.reduce((sum, item) => {
        if (item.checked) {
          return sum + (item.borderedQty || 0) * (item.volume || 0);
        } else {
          return sum;
        }
      }, 0);
    },
    getTotalMoney() {
      return this.productList.reduce((sum, item) => {
        if (item.checked && !isNaN(item.bprice)) {
          return sum + (item.borderedQty || 0) * (item.bprice || 0);
        } else {
          return sum;
        }
      }, 0);
    },
    getxj(bprice, borderedQty, item) {
      item.amount = new Number((bprice || 0) * (borderedQty || 0)).toFixed(2);
      return item.amount;
    },
    openAdd() {
      this.$emit("openAdd");
    }
  }
};
</script>

<style lang="less" scoped>
.prod-list-wrap {
  width: 1188px;
  margin-bottom: 0;
  background: #fff;
  padding-bottom: 16px;
  box-sizing: border-box;
  border-bottom: 16px solid #f7f7f7;

  .title-wrap {
    img {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }

    .tip {
      font-size: 16px;
      font-weight: 500;
      color: #262626;
      margin-right: 12px;
    }

    .orangeTip {
      font-size: 12px;
      font-weight: 400;
      color: #FF8F3C;
    }

    width: 1164px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 24px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.21) 0%, #ECF8F8 99%);
    border: 2px solid #FFFFFF;
    .freeText {
      background: #ffffff;
      box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.13);
      border-radius: 2px;
      float: right;
      margin-right: 783px !important;
      margin-top: -4px;

      .text {
        padding: 6px 12px 6px 12px;
        font-size: 12px;
        font-weight: 400;
        color: #ff8f3c;
      }
    }
  }

  .product-list-wrap {
    width: 1140px;
    margin: 0 auto;

    div {
      display: inline-block;
    }
    .product-info-wrap {
      //border: 1px solid #eeeeee;
      display: flex;
      flex-direction: column;
      // padding-bottom: 8px;
      margin-top: 16px;
      background: #F7F7F7;
      border-radius: 8px;
      position: relative;
      height: 219px;

      div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .chekImgBox {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 126px;
          margin-top: 22px;

          .check-prod-wrap {
            width: 20px;
            height: 20px;
          }

          .prod-img-wrap {
            margin-left: 24px;
            width: 100px;
            height: 100px;
            cursor: pointer;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;

            .prod-img {
              width: 80px;
              height: 80px;

            }
          }
        }

        .detail-wrap {
          margin-left: 16px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          padding-top: 30px;
          margin-right: 20px;


          .name {
            font-size: 14px;
            font-weight: 500;
            color: #262626;
            .freeShipping {
              margin-left: 0px;
              font-size: 12px;
              color: #fff;
              width: 42px;
               height: 17px;
              text-align: center;
               line-height: 17px;
              margin-right: 4px;
              background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
              border-radius: 2px;
              font-weight: 400;
            }
            .bright {
              font-size: 12px;
              color: #433538;
              width: 30px;
              height: 16px;
              text-align: center;
              line-height: 16px;
              background: linear-gradient(90deg, #D49879 0%, #FEF2DC 50%, #D49879 100%);
              border-radius: 2px;
              font-weight: 400;
            }
          }

          .colorGroup {
            display: flex;
            align-items: center;
            min-width: 200px;
            margin-top: 14px;

            .color {
              // padding-top: 8px;
              font-size: 12px;
              font-weight: 400;
              color: #777;
            }
            .hk {
              color: #262626;
            }

            .two {
              cursor: pointer;
              text-align: left;
              max-width: 130px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .hButton {
            padding-top: 8px;
          }

          .tiji {
            display: flex;
            text-align: start;
            margin-top: 6px;

            .brand {
              // padding-top: 8px;
              font-size: 12px;
              font-weight: 400;
              color: #777;

              .freeShipping {
                margin-left: 0px;
                font-size: 12px;
                color: #fff;
                width: 42px;
                // height: 16px;
                text-align: center;
                // line-height: 16px;
                margin-right: 0px;
                background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
                border-radius: 2px;
                font-weight: 400;
              }
              .bright {
                font-size: 12px;
                color: #433538;
                width: 30px;
                height: 16px;
                text-align: center;
                line-height: 16px;
                background: linear-gradient(90deg, #D49879 0%, #FEF2DC 50%, #D49879 100%);
                border-radius: 2px;
                margin:0 4px 0 0px;
                font-weight: 400;
              }
            }
            .hk {
              color: #262626;
            }

            .info {
              padding-left: 16px;
              padding-top: 8px;
              font-size: 12px;
              font-weight: 400;
              color: #777;
            }
            .ck{
              margin-top: 6px;
            }
          }

        }

        .price-wrap {
          margin-left: 16px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          padding-top: 65px;
          // padding-left: 60px;
          display: flex;
          flex-direction: column;
          margin-right: 20px;

          .price {
            .new-price {
              font-size: 14px;
              font-weight: 500;
              color: #262626;

              .ant-input-number {
                display: inline-block;
                width: 80px;
                height: 28px;
                border-radius: 4px;
                border: 1px solid #cccccc;
                text-align: center;
                line-height: 28px;
                margin-right: 5px;
              }

              /deep/ .ant-input:focus {
                border-color: rgba(0, 170, 166, 1) !important;
              }
            }

            .old-price {
              line-height: 20px;
              font-size: 14px;
              color: #777;
              text-decoration: line-through;
              margin-left: 10px;
            }
          }

          .discutBox {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            //padding-top: 8px;

            .discuinfo {
              font-size: 12px;
              font-weight: 400;
              color: #777;
              margin-right: 16px;
            }
            .discuinfo:first-child {
              margin-bottom: 1px;
            }
          }

          .info {
            //padding-top: 16px;
            font-size: 12px;
            font-weight: 400;
            color: #777;
          }
          .ck{
            margin-top: 6px;
          }
        }

        .price-wrapF {
          display: flex;
          flex-direction: row;
          margin-right: 90px;

          div {
            .info {
              margin-right: 16px;
            }
          }

        }

        .price-wrap1 {
          //margin-left: 16px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          padding-top: 30px;
          // padding-left: 60px;
          width: 112px;

          /deep/ .ant-select-selection {
            width: 150px !important;
          }

          /deep/ .ant-select-selection--single {
            width: 150px !important;
          }

          .price {

            .new-price {
              font-size: 14px;
              font-weight: 500;
              color: #262626;

              .ant-input-number {
                display: inline-block;
                width: 80px;
                height: 28px;
                border-radius: 4px;
                border: 1px solid #cccccc;
                text-align: center;
                line-height: 28px;
                margin-right: 5px;
              }

              /deep/ .ant-input:focus {
                border-color: rgba(0, 170, 166, 1) !important;
              }
            }

            .old-price {
              line-height: 20px;
              font-size: 14px;
              color: #777;
              text-decoration: line-through;
              margin-left: 10px;
            }
          }

          .discutBox {
            display: flex;
            align-items: flex-start;
            //padding-top: 8px;

            .discuinfo {
              font-size: 12px;
              font-weight: 400;
              color: #777;
            }
          }

          .info {
            //padding-top: 16px;
            font-size: 12px;
            font-weight: 400;
            color: #777;
          }
        }

        .count-wrap {
          //width: 220px;
          //margin-top: 10px;
          padding-left: 40px;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;

          p {
            //width: 42px;
            margin-right: 6px;
          }

          .number-box {
            /deep/ .btn-number {
              background: #20C6BA;
              height: 32px;
              line-height: 32px;
              color:#fff
            }
            /deep/ .disbled {
              background: #ddd;
            }

            /deep/ .number-container {
              width: 54px !important;
              height: 32px;
              border-top:1px solid #20C6BA ;
              border-bottom:1px solid #20C6BA ;

            }

            //margin-top: 22px;
          }


          .info {
            // padding-top: 24px;
            font-size: 12px;
            font-weight: 400;
            color: #777;
            text-align: left;
            // min-width: 77px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

          }

          .share {
            padding-left: 16px;
          }

        }

        .money-wrap {
          width: 164px;
          margin-left: 14px;
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .operate-wrap {
            margin-top: 44px;

            .zehngce {
              font-size: 12px;
              font-weight: 400;
              color: #777;
              line-height: 16px;
            }

            .colloe {
              cursor: pointer;
              font-size: 14px;

              span {
                font-size: 14px;
                color: #1890FF !important;
              }

              .icon-shouqi {
                font-size: 12px;
                transform: scale(0.7);
                margin-left: 2px;
              }
            }
          }

          .sum-wrap {
            .sum {
              font-size: 14px;
              font-weight: 500;
              color: #262626;
              line-height: 20px;
            }
          }
        }

        .close {
          margin-top: 38px;
          margin-left: 10px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #777;
          // background-color: rgb(110, 112, 112);
          cursor: pointer;
          position: absolute;
          right: 24px;

          .iconfontgwc {
            margin-top: -6px;
            // background-color: aquamarine;
            font-size: 20px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }

        }

        .iconfontgwc:hover {
          margin-top: -6px;
          // background-color: #00AAA6;
          // margin-top: -4px;
          background-image: url(../../../assets/cart/close.png);
          color: #fff;
          background-size: 100%;

        }
      }

      .typeing-wrap {
        position: absolute;
        width: 469px;
        height: 52px;
        background: #ECF8F8;
        border: 1px solid rgba(0, 170, 166, 0.24);
        //bottom: 18px;
        left: 24px;
        text-align: left;
        top: 145px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .typeing-wrap:before,
      .typeing-wrap:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border: solid transparent;
        pointer-events: none;
        content: "";
        border-color: rgba(136, 183, 213, 0);
      }

      .typeing-wrap.down:before {
        border-bottom-color: #BBE4E3;
        border-width: 12px;
        left: 13%;
        margin-left: -9px;
        bottom: 100%;
      }

      .typeing-wrap.down:after {
        border-bottom-color: #ECF8F8;
        border-width: 10px;
        left: 13.5%;
        margin-left: -10px;
        bottom: 100%;
      }


      .lab {
        margin-left: 24px;
      }

      .chose-account {
        width: 150px;
      }

      .number-box {
        width: 112px;

        /deep/ .number-container {
          max-width: 54px !important;
          border-top: 1px solid #20C6BA;
          border-bottom: 1px solid #20C6BA;
        }
        /deep/.disbled {
          background: #eee;
        }

        //margin-left: 24px;
      }
    }

    .choosePlice {
      margin-top: -26px;
      padding-left: 184px;
      padding-top: 8px;
      padding-bottom: 20px;
      display: flex;
      // justify-content: space-between;
      align-items: flex-start;

      .zehngce {
        color: #fff;
        font-size: 12px;
        display: block;
        width: 106px;
        height: 18px;
        // background: #FD6A6E;
        background-image: url(../../../assets/cart/police.png);
        background-size: 100%;
      }

      .zc-val-item {
        padding-left: 24px;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .chooseItem {
          font-size: 12px;
          color: #262626 !important;
          white-space: nowrap;
          overflow: hidden;
        }

        .namepliceBox {
          text-align: left;
          min-width: 90px;
          height: 20px;
          border-radius: 2px;
          // display: flex;
          // align-items: center;
          line-height: 20px;
          justify-content: flex-start;
          background-color: #f2f3f5 !important;
          font-size: 12px;

          .nameplice {
            display: flex;
            align-items: center;
            padding-left: 6px;
            color: #262626 !important;

          }

          .icon-guanbi-gouwuche {
            padding-left: 8px;
            padding-right: 4px;
            font-size: 16px;
            // margin-top: -2px;
            color: #AAAAAA;
          }
        }
      }

    }

  }

}

.modelClick {
  width: 460px;
  height: 38px;
  background: #ffffff;
  border: 1px dashed #cccccc;
  margin: 16px auto;
  margin-bottom: 0;
  border-radius: 4px;
  line-height: 38px;
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;;
  cursor: pointer;
  margin-bottom: 8px;
  img {
    width: 8px;
    height: 8px;
    margin-right: 4px;
  }
}

.modelClick:hover {
  color: #1890FF;
  border: 1px dashed #1890FF;
  background: rgba(24, 144, 255, 0.08);
}


.sum-wrap-wrap {
  width: 1188px;
  margin: 0 auto;
  padding: 24px 0;
  background: #fff;
  padding-top: 0;
  // margin-bottom: 16px;

  .sum-wrap {
    width: 1140px;
    height: 88px;
    background: #f7f7f7;
    margin: 0 auto;
    padding-right: 24px;
    // padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .info {
      text-align: right;
      color: #777777;
      font-size: 12px;
      line-height: 24px;

      span {
        color: #262626;
      }

      .problemfang {
        margin-left: 6px;
        color: #0caeaa;
        cursor: pointer;
      }

      .yufu {
        margin-right: 80px;
      }
    }
  }
}
</style>
